<template>
  <div>
    <el-dialog
      title="接收人"
      :visible.sync="dialogVisible"
      width="90%"
      top="4vh"
      @close="close"
    >
      <div
        :style="{
          height: scrollerHeight,
          overflow: 'auto',
        }"
      >
        <el-tree
          ref="treeRef"
          v-loading="loading"
          node-key="id"
          :data="TreeData"
          :props="props"
          show-checkbox
          :default-expand-all="false"
          :default-checked-keys="defkeys"
        >
          <span slot-scope="{ node }" class="custom-tree-node">
            <span>
              <i class="el-icon-user-solid"></i>
              {{ node.label }}
            </span>
          </span>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getFyrTree } from '@/api/staffManagement'
  import store from "@/store";
  import {Emas} from "@/assets/js/Emas";
  export default {
    name: 'FYR',
    data() {
      return {
        dialogVisible: false,
        Type: 0,
        TreeData: [],
        loading: false,
        props: {
          label: 'name',
          id: 'id',
          children: 'children',
        },
        defkeys: [],
        Staff_ID_Arr: [], //人事ID数组
        Staff_Name_Arr: [], //人事名称数组
      }
    },
    computed: {
      scrollerHeight: function () {
        return window.innerHeight - 300 + 'px'
      },
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/fyr", "附议人", "zzdzx.yy.gov.cn/fyr")
    },

    methods: {
      //树形组件渲染

      async fetchData() {
        this.loading = true
        const { data } = await getFyrTree({ Type: this.Type })
        this.TreeData = data
        this.loading = false
      },

      //树回绑  val当前Dept_Type  ID_Arr 选择的id数组
      showTree(val, ID_Arr) {
        this.defkeys = ID_Arr
        this.Type = val
        this.fetchData()
        this.dialogVisible = true
        this.setCheckedKeys(ID_Arr)
      },
      //设置回绑
      setCheckedKeys(ID_Arr) {
        this.$nextTick(() => {
          this.$refs.treeRef.setCheckedKeys(ID_Arr)
        })
      },

      //获取选中的节点
      getCheckedNodes() {
        let arr = this.$refs.treeRef.getCheckedNodes()
        let arr_ID = [] //保存选中的id
        let arr_Name = [] //保存选中的名称

        arr.forEach(function (item) {
          if (item.flag === 2) {
            arr_ID.push(item.id)
            arr_Name.push(item.name)
          }
        })
        this.Staff_Name_Arr = arr_Name
        this.Staff_ID_Arr = arr_ID
      },

      //提交之后，推送id和name的 对象  父组件获取方法统一为getTree
      save() {
        this.getCheckedNodes()

        console.log(this.Staff_ID_Arr)
        console.log(this.Staff_Name_Arr)

        let obj = null
        if (this.Staff_Name_Arr.length !== 0) {
          obj = {
            Staff_Name_Arr: this.Staff_Name_Arr,
            Staff_ID_Arr: this.Staff_ID_Arr,
          }
        }
        this.$emit('getTree', obj)
        this.close()
      },

      close() {
        this.TreeData = []
        this.Staff_ID_Arr = []
        this.Staff_Name_Arr = []
        this.dialogVisible = false
      },
    },
  }
</script>

<style scoped></style>
