import { request } from '@/util/request'

export function getList(params) {
  return request({
    url: '/api/DeptRole/GetList',
    method: 'get',
    params,
  })
}

export function doEdit(data) {
  return request({
    url: '/staffManagement/doEdit',
    method: 'post',
    data,
  })
}

export function doDelete(params) {
  return request({
    url: '/api/DeptRole/DeleteById',
    method: 'delete',
    params,
  })
}

export function setDeptRole(data) {
  return request({
    url: '/api/DeptRole/SetDeptRole',
    method: 'PUT',
    data,
  })
}

export function getDeptRole(data) {
  return request({
    url: '/api/DeptRole/GetDeptRole',
    method: 'POST',
    data,
  })
}

//获取人事信息拉下框
export function GetOptions(data) {
  return request({
    url: '/api/PropertyDic/GetOptions',
    method: 'post',
    data,
  })
}

//获取人事信息拉下框
export function getUserInfo(params) {
  return request({
    url: '/api/HrSatff/GetUserInfo',
    method: 'get',
    params,
  })
}

//获取人事信息拉下框
export function getFyrTree(params) {
  return request({
    url: '/api/HrSatff/GetFyrTree',
    method: 'get',
    params,
  })
}

export function getLeaderList(params) {
  return request({
    url: '/api/HrSatff/GetLeaderList',
    method: 'get',
    params,
  })
}

export function AsyncDingTalkUserID(params) {
  return request({
    url: '/api/HrSatff/AsyncDingTalkUserID',
    method: 'get',
    params,
  })
}
